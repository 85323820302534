/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-case-declarations */
import defaults from "../../defaults";

const GET_NOTIFICATIONS = "notifications/GET_NOTIFICATIONS";
const GET_NOTIFICATIONS_SUCCESS = "notifications/GET_NOTIFICATIONS_SUCCESS";
const GET_NOTIFICATIONS_FAIL = "notifications/GET_NOTIFICATIONS_FAIL";

const GET_NOTIFICATIONS_STATUS = "notifications/GET_NOTIFICATIONS_STATUS";
const GET_NOTIFICATIONS_STATUS_SUCCESS =
	"notifications/GET_NOTIFICATIONS_STATUS_SUCCESS";
const GET_NOTIFICATIONS_STATUS_FAIL =
	"notifications/GET_NOTIFICATIONS_STATUS_FAIL";

const GET_NOTIFICATIONS_AND_NOTIFICATION_PREFERENCES =
	"notifications/GET_NOTIFICATIONS_AND_NOTIFICATION_PREFERENCES";
const GET_NOTIFICATIONS_AND_NOTIFICATION_PREFERENCES_SUCCESS =
	"notifications/GET_NOTIFICATIONS_AND_NOTIFICATION_PREFERENCES_SUCCESS";
const GET_NOTIFICATIONS_AND_NOTIFICATION_PREFERENCES_FAIL =
	"notifications/GET_NOTIFICATIONS_AND_NOTIFICATION_PREFERENCES_FAIL";

const SET_NOTIFICATIONS_PREFERENCES =
	"notifications/SET_NOTIFICATIONS_PREFERENCES";
const SET_NOTIFICATIONS_PREFERENCES_SUCCESS =
	"notifications/SET_NOTIFICATIONS_PREFERENCES_SUCCESS";
const SET_NOTIFICATIONS_PREFERENCES_FAIL =
	"notifications/SET_NOTIFICATIONS_PREFERENCES_FAIL";

const GET_ACTIVITIES = "notifications/GET_ACTIVITIES";
const GET_ACTIVITIES_SUCCESS = "notifications/GET_ACTIVITIES_SUCCESS";
const GET_ACTIVITIES_FAIL = "notifications/GET_ACTIVITIES_FAIL";

const REMOVE_ACTIVITIES = "notifications/REMOVE_ACTIVITIES";
const REMOVE_ACTIVITIES_SUCCESS = "notifications/REMOVE_ACTIVITIES_SUCCESS";
const REMOVE_ACTIVITIES_FAIL = "notifications/REMOVE_ACTIVITIES_FAIL";

const initialState = defaults.userpanel.notifications;

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case GET_NOTIFICATIONS:
			return {
				...state,
				received: {
					...state.received,
					loading: true,
				},
			};
		case GET_NOTIFICATIONS_SUCCESS:
			return {
				...state,
				received: {
					...state.received,
					loading: false,
					loaded: true,
					data: action.result.data?.notifications ?? [],
					total: action.result.data?.total ?? 0,
					pagination: {
						totalpages:
							action.result.data?.pagination.totalpages ?? 1,
						currentpage:
							action.result.data?.pagination.currentpage ?? 1,
					},
				},
				pushNotifications: {
					other: action.result?.data?.settings?.other,
				},
			};
		case GET_NOTIFICATIONS_FAIL:
			return {
				...state,
				received: {
					...initialState.received,
					loading: false,
				},
			};
		case GET_ACTIVITIES:
			return {
				...state,
				activity: {
					...state.activity,
					loading: true,
				},
			};
		case GET_ACTIVITIES_SUCCESS:
			return {
				...state,
				activity: {
					loading: false,
					loaded: true,
					total: action.result.data?.total ?? 0,
					data: action.result.data?.notifications ?? [],
					pagination: {
						totalpages:
							action.result.data?.pagination.totalpages ?? 1,
						currentpage:
							action.result.data?.pagination.currentpage ?? 1,
					},
				},
			};
		case GET_ACTIVITIES_FAIL:
			return {
				...state,
				activity: {
					...state.activity,
					loading: false,
				},
			};
		case REMOVE_ACTIVITIES:
			return {
				...state,
				activity: {
					...state.activity,
					whileRemoving: true,
				},
			};
		case REMOVE_ACTIVITIES_SUCCESS:
			return {
				...state,
				activity: {
					...state.activity,
					whileRemoving: false,
				},
			};
		case REMOVE_ACTIVITIES_FAIL:
			return {
				...state,
				activity: {
					...state.activity,
					whileRemoving: false,
				},
			};
		case GET_NOTIFICATIONS_AND_NOTIFICATION_PREFERENCES:
			return {
				...state,
				received: {
					...state.received,
					loading: true,
				},
				preferences: {
					...state.preferences,
					loading: true,
				},
			};
		case GET_NOTIFICATIONS_AND_NOTIFICATION_PREFERENCES_SUCCESS:
			const { notificationList, notificationPreference } =
				action.result.data;
			return {
				...state,
				received: {
					...state.received,
					loading: false,
					loaded: true,
					data: notificationList.response?.notifications ?? [],
					total: notificationList.response?.total ?? 0,
					pagination: {
						totalpages:
							notificationList.response?.pagination.totalpages ??
							1,
						currentpage:
							notificationList.response?.pagination.currentpage ??
							1,
					},
				},
				pushNotifications: {
					other: notificationList?.response?.settings?.other,
				},
				preferences: {
					...state.preferences,
					loading: false,
					loaded: true,
					success: true,
					selected: notificationPreference.response
						.map(({ type, checked }) => (checked ? type : null))
						.filter((item) => item),
				},
			};
		case GET_NOTIFICATIONS_AND_NOTIFICATION_PREFERENCES_FAIL:
			return {
				...state,
				received: {
					...initialState.received,
					loading: false,
				},
				preferences: {
					...state.preferences,
					loading: false,
					loaded: true,
					success: false,
				},
			};
		case SET_NOTIFICATIONS_PREFERENCES_SUCCESS:
			return {
				...state,
				preferences: {
					...state.preferences,
					selected: action.values,
				},
			};
		case GET_NOTIFICATIONS_STATUS:
			return {
				...state,
			};
		case GET_NOTIFICATIONS_STATUS_SUCCESS:
			return {
				...state,
				pushNotifications: {
					other: action?.result?.data?.settings?.other,
				},
			};
		case GET_NOTIFICATIONS_STATUS_FAIL:
			return {
				...state,
			};
		default:
			return state;
	}
}

/**
 * Zwraca listę otrzymanych powiadomień
 * @api GET getUserNotifications
 * @param {Number} page numer strony
 */
export function getReceived({ page = 1, id_fcm, hash_fcm }) {
	return {
		types: [
			GET_NOTIFICATIONS,
			GET_NOTIFICATIONS_SUCCESS,
			GET_NOTIFICATIONS_FAIL,
		],
		promise: (client) =>
			client.get(`/notification`, {
				params: {
					page,
					id_fcm,
					hash_fcm,
				},
			}),
	};
}

export function getNotificationsStatus({ firebaseId, firebaseHash }) {
	return {
		types: [
			GET_NOTIFICATIONS_STATUS,
			GET_NOTIFICATIONS_STATUS_SUCCESS,
			GET_NOTIFICATIONS_STATUS_FAIL,
		],
		promise: (client) =>
			client.get(`/setting/fcm`, {
				params: {
					id: firebaseId || undefined,
					hash: firebaseHash || undefined,
				},
			}),
	};
}

/**
 * Zwraca listę powiadomień oraz listę preferencji powiadomień
 * @api GET getNotificationsAndNotificationPreference
 */
export function getNotificationsAndNotificationPreference({
	page,
	id_fcm,
	hash_fcm,
}) {
	return {
		types: [
			GET_NOTIFICATIONS_AND_NOTIFICATION_PREFERENCES,
			GET_NOTIFICATIONS_AND_NOTIFICATION_PREFERENCES_SUCCESS,
			GET_NOTIFICATIONS_AND_NOTIFICATION_PREFERENCES_FAIL,
		],
		promise: (client) =>
			client.post(
				"/merge/bulk",
				JSON.stringify([
					{
						key: "notificationList",
						method: "GET",
						url: `/notification`,
						args: {
							page,
							id_fcm,
							hash_fcm,
						},
					},
					{
						key: "notificationPreference",
						method: "GET",
						url: `/notification/preference`,
						args: {},
					},
				])
			),
	};
}

/**
 * Zmienia listę preferencji powiadomień
 * @api PUT getNotificationPreference
 */
export function changeNotificationPreference(values) {
	const data = {
		like: false,
		comment: false,
		invite: false,
		friend: false,
		payment: false,
	};
	Object.keys(data).forEach((key) => {
		data[key] = values.find((value) => value === key) ? true : false;
	});
	return {
		types: [
			SET_NOTIFICATIONS_PREFERENCES,
			SET_NOTIFICATIONS_PREFERENCES_SUCCESS,
			SET_NOTIFICATIONS_PREFERENCES_FAIL,
		],
		promise: (client) => client.put("/notification/preference", data),
		values,
	};
}

/**
 * Zwraca listę dziennika aktywności
 * @api GET getUserSelfNotifications
 * @param {Number} page numer strony
 */
export function getActivities(page = 1) {
	return {
		types: [GET_ACTIVITIES, GET_ACTIVITIES_SUCCESS, GET_ACTIVITIES_FAIL],
		promise: (client) =>
			client.get(`/notification/self`, {
				params: {
					page,
				},
				headers: {
					Accept: "application/api.1.2+json",
				},
			}),
	};
}

/**
 * Usuwa wybrane aktywności
 * @api DELETE deleteUserSelfNotificationMultiple
 * @param {Number} ids numery id do usuniecia
 */
export function removeActivities(ids) {
	return {
		types: [
			REMOVE_ACTIVITIES,
			REMOVE_ACTIVITIES_SUCCESS,
			REMOVE_ACTIVITIES_FAIL,
		],
		promise: (client) =>
			client.delete(`/notification/self/multiple`, {
				params: {
					ids: ids.join(","),
				},
			}),
	};
}
