/* eslint-disable no-unused-vars */
/* eslint-disable no-case-declarations */
import defaults from "../defaults";

const GET_PROFILE_DETAILS_MERGE = "user/GET_PROFILE_DETAILS_MERGE";
const GET_PROFILE_DETAILS_MERGE_SUCCESS =
	"user/GET_PROFILE_DETAILS_MERGE_SUCCESS";
const GET_PROFILE_DETAILS_MERGE_FAIL = "user/GET_PROFILE_DETAILS_MERGE_FAIL";

const SET_PROFILE_DETAILS_MERGE_DEFAULT =
	"user/SET_PROFILE_DETAILS_MERGE_DEFAULT";

const GET_PROFILE_ALBUMS = "user/GET_PROFILE_ALBUMS";
const GET_PROFILE_ALBUMS_SUCCESS = "user/GET_PROFILE_ALBUMS_SUCCESS";
const GET_PROFILE_ALBUMS_FAIL = "user/GET_PROFILE_ALBUMS_FAIL";

const GET_PROFILE_ALBUM_GALLERIES = "user/GET_PROFILE_ALBUM_GALLERIES";
const GET_PROFILE_ALBUM_GALLERIES_SUCCESS =
	"user/GET_PROFILE_ALBUM_GALLERIES_SUCCESS";
const GET_PROFILE_ALBUM_GALLERIES_FAIL =
	"user/GET_PROFILE_ALBUM_GALLERIES_FAIL";

const GET_PROFILE_ALBUM_RELOAD = "user/GET_PROFILE_ALBUM_RELOAD";
const GET_PROFILE_ALBUM_RELOAD_SUCCESS =
	"user/GET_PROFILE_ALBUM_RELOAD_SUCCESS";
const GET_PROFILE_ALBUM_RELOAD_FAIL = "user/GET_PROFILE_ALBUM_RELOAD_FAIL";

const GET_PROFILE_FRIENDS = "user/GET_PROFILE_FRIENDS";
const GET_PROFILE_FRIENDS_SUCCESS = "user/GET_PROFILE_FRIENDS_SUCCESS";
const GET_PROFILE_FRIENDS_FAIL = "user/GET_PROFILE_FRIENDS_FAIL";

const GET_PROFILE_FOLLOWERS = "user/GET_PROFILE_FOLLOWERS";
const GET_PROFILE_FOLLOWERS_SUCCESS = "user/GET_PROFILE_FOLLOWERS_SUCCESS";
const GET_PROFILE_FOLLOWERS_FAIL = "user/GET_PROFILE_FOLLOWERS_FAIL";

const GET_PROFILE_FOLLOWING = "user/GET_PROFILE_FOLLOWING";
const GET_PROFILE_FOLLOWING_SUCCESS = "user/GET_PROFILE_FOLLOWING_SUCCESS";
const GET_PROFILE_FOLLOWING_FAIL = "user/GET_PROFILE_FOLLOWING_FAIL";

const GET_PROFILE_MEDIA = "user/GET_PROFILE_MEDIA";
const GET_PROFILE_MEDIA_SUCCESS = "user/GET_PROFILE_MEDIA_SUCCESS";
const GET_PROFILE_MEDIA_FAIL = "user/GET_PROFILE_MEDIA_FAIL";

const BLOCK_USER = "user/BLOCK_USER";
const BLOCK_USER_SUCCESS = "user/BLOCK_USER_SUCCESS";
const BLOCK_USER_FAIL = "user/BLOCK_USER_FAIL";

const UNBLOCK_USER = "user/UNBLOCK_USER";
const UNBLOCK_USER_SUCCESS = "user/UNBLOCK_USER_SUCCESS";
const UNBLOCK_USER_FAIL = "user/UNBLOCK_USER_FAIL";

const FOLLOW_USER = "user/FOLLOW_USER";
const FOLLOW_USER_SUCCESS = "user/FOLLOW_USER_SUCCESS";
const FOLLOW_USER_FAIL = "user/FOLLOW_USER_FAIL";

const UNFOLLOW_USER = "user/UNFOLLOW_USER";
const UNFOLLOW_USER_SUCCESS = "user/UNFOLLOW_USER_SUCCESS";
const UNFOLLOW_USER_FAIL = "user/UNFOLLOW_USER_FAIL";

const ADD_FRIEND = "user/ADD_FRIEND";
const ADD_FRIEND_SUCCESS = "user/ADD_FRIEND_SUCCESS";
const ADD_FRIEND_FAIL = "user/ADD_FRIEND_FAIL";

const ACCEPT_FRIEND = "user/ACCEPT_FRIEND";
const ACCEPT_FRIEND_SUCCESS = "user/ACCEPT_FRIEND_SUCCESS";
const ACCEPT_FRIEND_FAIL = "user/ACCEPT_FRIEND_FAIL";

const DELETE_FRIEND = "user/DELETE_FRIEND";
const DELETE_FRIEND_SUCCESS = "user/DELETE_FRIEND_SUCCESS";
const DELETE_FRIEND_FAIL = "user/DELETE_FRIEND_FAIL";

const SET_USER_STATUS = "user/SET_USER_STATUS";

const SET_USER_SUSPENSION = "user/SET_USER_SUSPENSION";

const SET_USER_LIMIT = "user/SET_USER_LIMIT";

const initialState = defaults.user;

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case SET_PROFILE_DETAILS_MERGE_DEFAULT:
			return {
				...initialState,
				main: {
					...initialState.main,
					loading: true,
				},
				gallery: {
					...initialState.gallery,
				},
				media: {
					...initialState.media,
				},
			};
		case GET_PROFILE_DETAILS_MERGE:
			return {
				...initialState,
				main: {
					...initialState.main,
					loading: true,
					error: false,
					loaded: false,
				},
				gallery: {
					...initialState.gallery,
				},
				media: {
					...initialState.media,
				},
			};
		case GET_PROFILE_DETAILS_MERGE_SUCCESS:
			const {
				gallery: { response: gallery },
				media: { response: media },
				user: { response: user },
			} = action.result.data || {};

			const isAllowed2 = !(
				user.frozen === true ||
				user.status?.block_by_me ||
				user.status?.block_me
			);

			const mergedState = {
				gallery: {
					...state.gallery,
					loading: false,
					loaded: true,
					data: gallery ?? [],
				},
				media: {
					...state.media,
					loading: false,
					loaded: true,
					total: media?.total ?? 0,
					data: media?.media || [],
					pagination: {
						totalPages: media?.pagination?.totalpages ?? 1,
						currentPage: media?.pagination?.currentpage ?? 1,
					},
					hash: media?.hash,
				},
			};

			const data2 = () => {
				const notFrozemData = {
					languages: user.languages ?? [],
					location: {
						city: user?.city_is_spam ? "[SPAM]" : user.city,
						cityId: user.city_id,
						region: user.region,
						regionId: user.region_id,
						country: user.country,
						urlSearch: user.url_search,
					},
					preferences: user.preferences ?? [],
					relationship: user.relationship,
					description: {
						isSpam: user?.is_description_spam ?? false,
						plain: user.description_raw,
						parsed: user.description,
						cut: user.description_cut,
					},
					create: user.create,
					active: user.active,
					online: user.online,
					profileStatus: user.profile_status,
					privateData: user.private_data,
					kids: user.kids,
					statistics: {
						userGallery:
							(user.statistics?.galleries?.public || 0) +
							(user.statistics?.galleries?.private || 0),
						userCamera: user.status?.camera || false,
						userFriends: user.statistics?.friends || 0,
						userFollowers: user.statistics?.followers || 0,
						userFollowing: user.statistics?.user_follow || 0,
						likes: user.statistics?.likes || 0,
					},
					tolerances: {
						canSendMessage:
							user.tolerances?.can_send_message || false,
						canComment: user.tolerances?.can_comment || false,
						canDeleteFriend:
							user.tolerances?.can_delete_friend || false,
						canFollow: user.tolerances?.can_follow || false,
						canInviteFriend:
							user.tolerances?.can_invite_friend || false,
						canLike: user.tolerances?.can_like || false,
						canUnfollow: user.tolerances?.can_unfollow || false,
						filterUserSex:
							user.tolerances?.filter_user_sex || false,
						limit: user.tolerances?.limit || false,
					},
					search: {
						search_sex: [
							...Object.keys(user.search_sex).filter(
								(item) => user.search_sex[item]
							),
						],
						search_age: {
							from: user.search_age?.from,
							to: user.search_age?.to,
						},
						search_for: [
							...Object.keys(user.search_for_new).filter(
								(item) => user.search_for_new[item]
							),
						],
					},
					camera: {
						id: user.status?.camera?.id ?? false,
						viewers:
							(user.status?.camera?.ilu_ogladajacych ||
								user.status?.camera?.views) ??
							0,
					},
				};

				return {
					...state.main,
					loading: false,
					loaded: true,
					loadedFull: true,
					error: false,
					login: user.login,
					id: user.id,
					avatar: user.avatar,
					status: {
						blockByMe: user.status?.block_by_me || false,
						blockMe: user.status?.block_me || false,
						followStatus: user.status?.follow_status || 0,
						friendStatus: user.status?.friend_status || 0,
						isReport: user.status?.is_report || false,
					},
					settings: {
						showAge: user?.settings?.show_age || true,
						showStatusVip: user?.settings?.show_status_vip || true,
						soundEnable: user?.settings?.sound_enable || true,
					},
					vip: user.status_vip,
					sex: user.sex,
					...notFrozemData,
				};
			};
			const coupleData2 = () => ({
				sex: [user.partner_1.sex, user.partner_2.sex],
				name: [
					user.partner_1?.name_is_spam
						? "[SPAM]"
						: user.partner_1.name,
					user.partner_2?.name_is_spam
						? "[SPAM]"
						: user.partner_2.name,
				],

				height: [user.partner_1.height, user.partner_2.height],
				figure: [user.partner_1.figure, user.partner_2.figure],
				zodiak: [user.partner_1.zodiak, user.partner_2.zodiak],
				hair: [user.partner_1.hair, user.partner_2.hair],
				alko: [user.partner_1.alko, user.partner_2.alko],
				smoke: [user.partner_1.smoke, user.partner_2.smoke],
				education: [user.partner_1.education, user.partner_2.education],
				age: [user.partner_1.age, user.partner_2.age],
			});
			const singleData2 = () => ({
				sex: [user.sex],
				name: [
					user.user_data?.name_is_spam
						? "[SPAM]"
						: user.user_data.name,
				],
				height: [user.user_data.height],
				figure: [user.user_data.figure],
				zodiak: [user.user_data.zodiak],
				hair: [user.user_data.hair],
				alko: [user.user_data.alko],
				smoke: [user.user_data.smoke],
				education: [user.user_data.education],
				age: [user.user_data.age],
			});

			return isAllowed2
				? {
						...state,
						main: {
							...data2(),
							isAllowed: isAllowed2,
							partners:
								user.sex === "couple"
									? coupleData2()
									: singleData2(),
							fromServer: action.fromServer,
							locationMd5Hash: action.locationMd5Hash,
						},
						...mergedState,
				  }
				: {
						...state,
						main: {
							...state.main,
							loading: false,
							loaded: true,
							error: false,
							isAllowed: isAllowed2,
							frozen: user.frozen,
							login: user.login,
							id: user.id,
							avatar: user.avatar,
							status: {
								blockByMe: user.status?.block_by_me || false,
								blockMe: user.status?.block_me || false,
								followStatus: user.status?.follow_status || 0,
								friendStatus: user.status?.friend_status || 0,
							},
							location: {
								city: user.city,
								cityId: user.city_id,
								region: user.region,
								regionId: user.region_id,
								country: user.country,
								urlSearch: user.url_search,
							},
							vip: user.status_vip,
							fromServer: action.fromServer,
							locationMd5Hash: action.locationMd5Hash,
						},
						...mergedState,
				  };

		case GET_PROFILE_DETAILS_MERGE_FAIL:
			return {
				...state,
				main: {
					...state.main,
					loading: false,
					loaded: true,
					error: true,
					errorType: action.error,
				},
				gallery: {
					loading: false,
					loaded: true,
					error: true,
				},
				media: {
					loading: false,
					loaded: true,
					error: true,
				},
			};

		case GET_PROFILE_ALBUMS:
			return {
				...state,
				galleries: {
					...state.galleries,
					albums_list: {
						...state.galleries.albums_list,
						loading: true,
					},
				},
			};
		case GET_PROFILE_ALBUMS_SUCCESS:
			if (!action.result.data) {
				return {
					...state,
					galleries: {
						...state.galleries,
						albums_list: {
							...state.galleries.albums_list,
							loaded: true,
							loading: false,
						},
					},
				};
			}
			return {
				...state,
				galleries: {
					...state.galleries,
					albums_list: {
						loading: false,
						loaded: true,
						data: action.result.data ?? [],
					},
				},
			};
		case GET_PROFILE_ALBUMS_FAIL:
			return {
				...state,
				images: {
					...state.images,
					albums_list: {
						...state.images.albums_list,
						loading: false,
					},
				},
			};
		// ** Get Profile Album Images
		case GET_PROFILE_ALBUM_GALLERIES:
			if (action.reset) {
				return {
					...state,
					galleries: {
						...state.galleries,
						current_album: {
							...state.galleries.current_album,
							loading: true,
							[action.albumId]: {
								total: 0,
								photos: 0,
								videos: 0,
								hash: [],
								data: [],
								pagination: {
									totalPages: 1,
									currentPage: 1,
								},
							},
						},
					},
				};
			}
			return {
				...state,
				galleries: {
					...state.galleries,
					current_album: {
						...state.galleries.current_album,
						loading: true,
					},
				},
			};
		case GET_PROFILE_ALBUM_GALLERIES_SUCCESS:
			const dataResult = action.reset
				? [...(action.result.data?.media ?? [])]
				: [
						...(state.galleries.current_album[action.albumId]
							?.data ?? []),
						...(action.result.data?.media ?? []),
				  ];

			return {
				...state,
				galleries: {
					...state.galleries,
					current_album: {
						...state.galleries.current_album,
						loading: false,
						[action.albumId]: {
							total: action.result.data?.total,
							photos: action.result.data?.photos,
							videos: action.result.data?.videos,
							hash: action.result.data?.hash,
							data: action.reset
								? dataResult
								: dataResult.filter((val, id) => {
										return (
											id ===
											dataResult.findIndex(
												(el) => el.id === val.id
											)
										);
								  }),
							pagination: {
								totalPages:
									action.result.data?.pagination.totalpages ??
									1,
								currentPage:
									action.result.data?.pagination
										.currentpage ?? 1,
							},
						},
					},
				},
			};
		case GET_PROFILE_ALBUM_GALLERIES_FAIL:
			return {
				...state,
				galleries: {
					...state.galleries,
					current_album: {
						...state.galleries.current_album,
						loading: false,
					},
				},
			};

		// ** Get Profile Album Reload
		case GET_PROFILE_ALBUM_RELOAD:
			return {
				...state,
				galleries: {
					...state.galleries,
					current_album: {
						...state.galleries.current_album,
						loading: true,
						[action.albumId]: {
							total: 0,
							data: [],
							pagination: {
								totalPages: 1,
								currentPage: 1,
							},
						},
					},
				},
			};
		case GET_PROFILE_ALBUM_RELOAD_SUCCESS:
			return {
				...state,
				galleries: {
					...state.galleries,
					current_album: {
						...state.galleries.current_album,
						loading: false,
						[action.albumId]: {
							total: action.result.data?.total,
							hash: action.result.data?.hash,
							data: [...(action.result.data?.media ?? [])],
							pagination: {
								totalPages:
									action.result.data?.pagination.totalpages ??
									1,
								currentPage:
									action.result.data?.pagination
										.currentpage ?? 1,
							},
						},
					},
				},
			};
		case GET_PROFILE_ALBUM_RELOAD_FAIL:
			return {
				...state,
				galleries: {
					...state.galleries,
					current_album: {
						...state.galleries.current_album,
						loading: false,
					},
				},
			};
		case GET_PROFILE_FRIENDS:
			return {
				...state,
				friends: {
					...state.friends,
					current: {
						...state.friends.current,
						loading: true,
					},
				},
			};
		case GET_PROFILE_FRIENDS_SUCCESS:
			// eslint-disable-next-line no-case-declarations
			const newDataFriends = action.infinityScroll
				? [
						...state.friends.current.list,
						...(action.result.data?.users ?? []),
				  ]
				: action.result.data?.users ?? [];

			return {
				...state,
				friends: {
					...state.friends,
					current: {
						...state.friends.current,
						loading: false,
						loaded: true,
						success: true,
						error: false,
						list: action.infinityScroll
							? newDataFriends.filter((val, id) => {
									return (
										id ===
										newDataFriends.findIndex(
											(el) => el.id === val.id
										)
									);
							  })
							: newDataFriends,
						pagination: {
							totalPages:
								action.result.data?.pagination.totalpages ?? 1,
							currentPage:
								action.result.data?.pagination.currentpage ?? 1,
						},
					},
				},
			};
		case GET_PROFILE_FRIENDS_FAIL:
			return {
				...state,
				friends: {
					...state.friends,
					current: {
						...state.friends.current,
						loading: false,
						loaded: true,
						success: false,
						error: true,
					},
				},
			};
		case GET_PROFILE_FOLLOWERS:
			return {
				...state,
				friends: {
					...state.friends,
					followers: {
						...state.friends.followers,
						loading: true,
					},
				},
			};
		case GET_PROFILE_FOLLOWERS_SUCCESS:
			// eslint-disable-next-line no-case-declarations
			const newDataFriendsFollowers = action.infinityScroll
				? [
						...state.friends.followers.list,
						...(action.result.data?.users ?? []),
				  ]
				: action.result.data?.users ?? [];
			return {
				...state,
				friends: {
					...state.friends,
					followers: {
						...state.friends.followers,
						loading: false,
						loaded: true,
						success: true,
						error: false,
						list: action.infinityScroll
							? newDataFriendsFollowers.filter((val, id) => {
									return (
										id ===
										newDataFriendsFollowers.findIndex(
											(el) => el.id === val.id
										)
									);
							  })
							: newDataFriendsFollowers,
						pagination: {
							totalPages:
								action.result.data?.pagination.totalpages ?? 1,
							currentPage:
								action.result.data?.pagination.currentpage ?? 1,
						},
					},
				},
			};
		case GET_PROFILE_FOLLOWERS_FAIL:
			return {
				...state,
				followers: {
					...state.followers,
					current: {
						...state.friends.current,
						loading: false,
						loaded: true,
						success: false,
						error: true,
					},
				},
			};
		case GET_PROFILE_FOLLOWING:
			return {
				...state,
				friends: {
					...state.friends,
					following: {
						...state.friends.following,
						loading: true,
					},
				},
			};
		case GET_PROFILE_FOLLOWING_SUCCESS:
			// eslint-disable-next-line no-case-declarations
			const newDataFriendsFollowing = action.infinityScroll
				? [
						...state.friends.following.list,
						...(action.result.data?.users ?? []),
				  ]
				: action.result.data?.users ?? [];
			return {
				...state,
				friends: {
					...state.friends,
					following: {
						...state.friends.following,
						loading: false,
						loaded: true,
						success: true,
						error: false,
						list: action.infinityScroll
							? newDataFriendsFollowing.filter((val, id) => {
									return (
										id ===
										newDataFriendsFollowing.findIndex(
											(el) => el.id === val.id
										)
									);
							  })
							: newDataFriendsFollowing,
						pagination: {
							totalPages:
								action.result.data?.pagination.totalpages ?? 1,
							currentPage:
								action.result.data?.pagination.currentpage ?? 1,
						},
					},
				},
			};
		case GET_PROFILE_FOLLOWING_FAIL:
			return {
				...state,
				following: {
					...state.following,
					current: {
						...state.friends.current,
						loading: false,
						loaded: true,
						success: false,
						error: true,
					},
				},
			};
		case GET_PROFILE_MEDIA:
			return {
				...state,
				media: {
					...state.media,
					loading: true,
				},
			};
		case GET_PROFILE_MEDIA_SUCCESS:
			const newMedia = [
				...(action.isFirst ? [] : state.media.data),
				...(action.result.data?.media ?? []),
			];
			return {
				...state,
				media: {
					...state.media,
					loading: false,
					loaded: true,
					total: action.result.data?.total ?? 0,
					data: newMedia,
					pagination: {
						totalPages:
							action.result.data?.pagination.totalpages ?? 1,
						currentPage:
							action.result.data?.pagination.currentpage ?? 1,
					},
					hash: action.result.data?.hash,
				},
			};
		case GET_PROFILE_MEDIA_FAIL:
			return {
				...state,
				media: {
					...state.media,
					loading: false,
					loaded: true,
					error: true,
				},
			};
		case BLOCK_USER_SUCCESS: {
			return {
				...state,
				main: {
					...state.main,
					status: {
						blockByMe: action.result.data.status.block_by_me,
						blockMe: action.result.data.status.block_me,
						followStatus: action.result.data.status.follow_status,
						friendStatus: action.result.data.status.friend_status,
					},
				},
			};
		}
		case UNBLOCK_USER_SUCCESS: {
			return {
				...state,
				main: {
					...state.main,
					status: {
						blockByMe: action.result.data.status.block_by_me,
						blockMe: action.result.data.status.block_me,
						followStatus: action.result.data.status.follow_status,
						friendStatus: action.result.data.status.friend_status,
					},
				},
			};
		}
		case FOLLOW_USER_SUCCESS: {
			return {
				...state,
				main: {
					...state.main,
					status: {
						blockByMe: action.result.data.status.block_by_me,
						blockMe: action.result.data.status.block_me,
						followStatus: action.result.data.status.follow_status,
						friendStatus: action.result.data.status.friend_status,
					},
				},
			};
		}
		case UNFOLLOW_USER_SUCCESS: {
			return {
				...state,
				main: {
					...state.main,
					status: {
						blockByMe: action.result.data.status.block_by_me,
						blockMe: action.result.data.status.block_me,
						followStatus: action.result.data.status.follow_status,
						friendStatus: action.result.data.status.friend_status,
					},
				},
			};
		}
		case ADD_FRIEND_SUCCESS: {
			return {
				...state,
				main: {
					...state.main,
					status: {
						blockByMe: action.result.data.status.block_by_me,
						blockMe: action.result.data.status.block_me,
						followStatus: action.result.data.status.follow_status,
						friendStatus: action.result.data.status.friend_status,
					},
				},
			};
		}
		case ACCEPT_FRIEND_SUCCESS: {
			return {
				...state,
				main: {
					...state.main,
					status: {
						...state.main.status,
						friendStatus: action.result.data.status.friend_status,
					},
				},
			};
		}
		case DELETE_FRIEND_SUCCESS: {
			return {
				...state,
				main: {
					...state.main,
					status: {
						blockByMe: action.result.data.status.block_by_me,
						blockMe: action.result.data.status.block_me,
						followStatus: action.result.data.status.follow_status,
						friendStatus: action.result.data.status.friend_status,
					},
					tolerances: {
						canSendMessage:
							action.result.data.tolerances?.can_send_message ??
							false,
						canComment:
							action.result.data.tolerances?.can_comment ?? false,
						canDeleteFriend:
							action.result.data.tolerances?.can_delete_friend ??
							false,
						canFollow:
							action.result.data.tolerances?.can_follow ?? false,
						canInviteFriend:
							action.result.data.tolerances?.can_invite_friend ??
							false,
						canLike:
							action.result.data.tolerances?.can_like ?? false,
						canUnfollow:
							action.result.data.tolerances?.can_unfollow ??
							false,
						filterUserSex:
							action.result.data.tolerances?.filter_user_sex ??
							false,
						limit: action.result.data.tolerances?.limit ?? false,
					},
				},
			};
		}
		case SET_USER_STATUS: {
			return {
				...state,
				main: {
					...state.main,
					profileStatus: action.status,
				},
			};
		}

		case SET_USER_SUSPENSION: {
			return {
				...state,
				suspension: action.suspension,
			};
		}

		case SET_USER_LIMIT:
			return {
				...state,
				main: {
					...state.main,
					tolerances: {
						...state.main.tolerances,
						limit: action.payload,
					},
				},
			};

		default:
			return state;
	}
}

export function setProfileDetailsDefault() {
	return {
		type: SET_PROFILE_DETAILS_MERGE_DEFAULT,
	};
}

export function getProfileDetailsMerge(
	login,
	isAuth,
	fromServer = false,
	locationMd5Hash = ""
) {
	return {
		types: [
			GET_PROFILE_DETAILS_MERGE,
			GET_PROFILE_DETAILS_MERGE_SUCCESS,
			GET_PROFILE_DETAILS_MERGE_FAIL,
		],
		promise: (client) => {
			return client.post(
				isAuth ? "/merge/bulk" : "/merge/bulk/unauthorized",
				JSON.stringify([
					{
						key: "gallery",
						url: `media/gallery/login${
							isAuth ? "" : "/unauthorized"
						}`,
						args: {
							user: login,
						},
					},
					{
						key: "media",
						url: `media/login${isAuth ? "" : "/unauthorized"}`,
						args: {
							user: login,
							limit: 10,
						},
					},
					{
						key: "user",
						url: `user/login${isAuth ? "" : "/unauthorized"}`,
						args: {
							login,
						},
					},
				])
			);
		},
		fromServer,
		locationMd5Hash,
	};
}

export function getProfileAlbums(id, isAuth, showPrivateMedia = null) {
	return {
		types: [
			GET_PROFILE_ALBUMS,
			GET_PROFILE_ALBUMS_SUCCESS,
			GET_PROFILE_ALBUMS_FAIL,
		],
		promise: (client) =>
			client.get(`/album${isAuth ? "" : "/unauthorized"}/all`, {
				params: {
					user: id,
					withMedia: true,
					showMedia: isAuth ? showPrivateMedia : null,
				},
			}),
	};
}

export function getProfileAlbumGalleries(
	albumId,
	userId,
	page = 1,
	hash,
	isAuth,
	sort = "any",
	reset = false
) {
	return {
		types: [
			GET_PROFILE_ALBUM_GALLERIES,
			GET_PROFILE_ALBUM_GALLERIES_SUCCESS,
			GET_PROFILE_ALBUM_GALLERIES_FAIL,
		],
		promise: (client) =>
			client.get(`/media/album${isAuth ? "" : "/unauthorized"}`, {
				params: {
					id: albumId,
					user: userId,
					page,
					hash,
					sort,
				},
			}),
		albumId,
		reset,
	};
}

export function getProfileFriends(
	userId,
	isAuth = false,
	{ page, sex },
	infinityScroll = false
) {
	return {
		types: [
			GET_PROFILE_FRIENDS,
			GET_PROFILE_FRIENDS_SUCCESS,
			GET_PROFILE_FRIENDS_FAIL,
		],
		promise: (client) =>
			client.get(`/friend${isAuth ? "" : "/unauthorized"}`, {
				params: {
					id: userId,
					page: page || 1,
					sex: sex || "any",
					sort: "asc",
					limit: 30,
				},
			}),
		infinityScroll,
	};
}

export function getProfileFollowers(
	userId,
	isAuth = false,
	{ page, sex },
	infinityScroll = false
) {
	return {
		types: [
			GET_PROFILE_FOLLOWERS,
			GET_PROFILE_FOLLOWERS_SUCCESS,
			GET_PROFILE_FOLLOWERS_FAIL,
		],
		promise: (client) =>
			client.get(`/friend/follow${isAuth ? "" : "/unauthorized"}`, {
				params: {
					id: userId,
					page: page || 1,
					sex: sex || "any",
					sort: "asc",
					limit: 30,
				},
			}),
		infinityScroll,
	};
}
export function getProfileFollowing(
	userId,
	isAuth = false,
	{ page, sex },
	infinityScroll = false
) {
	return {
		types: [
			GET_PROFILE_FOLLOWING,
			GET_PROFILE_FOLLOWING_SUCCESS,
			GET_PROFILE_FOLLOWING_FAIL,
		],
		promise: (client) =>
			client.get(`/friend/follow-send${isAuth ? "" : "/unauthorized"}`, {
				params: {
					id: userId,
					page: page || 1,
					sex: sex || "any",
					sort: "asc",
					limit: 30,
				},
			}),
		infinityScroll,
	};
}

export function getProfileMedia(userId, page = 1, isAuth, hash, isFirst) {
	return {
		types: [
			GET_PROFILE_MEDIA,
			GET_PROFILE_MEDIA_SUCCESS,
			GET_PROFILE_MEDIA_FAIL,
		],
		promise: (client) =>
			client.get(`/media${isAuth ? "" : "/unauthorized"}`, {
				params: {
					user: userId,
					page,
					limit: 10,
					hash,
				},
			}),
		isFirst,
	};
}

export function blockUser(userId) {
	return {
		types: [BLOCK_USER, BLOCK_USER_SUCCESS, BLOCK_USER_FAIL],
		promise: (client) => client.put(`/user/block?id=${userId}`),
	};
}

export function unblockUser(userId) {
	return {
		types: [UNBLOCK_USER, UNBLOCK_USER_SUCCESS, UNBLOCK_USER_FAIL],
		promise: (client) =>
			client.delete(`/user/block`, {
				params: {
					id: userId,
				},
			}),
	};
}

export function followUser(userId) {
	return {
		types: [FOLLOW_USER, FOLLOW_USER_SUCCESS, FOLLOW_USER_FAIL],
		promise: (client) => client.put(`/friend/follow-send?id=${userId}`),
	};
}

export function unfollowUser(userId) {
	return {
		types: [UNFOLLOW_USER, UNFOLLOW_USER_SUCCESS, UNFOLLOW_USER_FAIL],
		promise: (client) =>
			client.delete(`/friend/follow-send`, {
				params: {
					id: userId,
				},
			}),
	};
}

export function addFriend(userId) {
	return {
		types: [ADD_FRIEND, ADD_FRIEND_SUCCESS, ADD_FRIEND_FAIL],
		promise: (client) => client.put(`/friend?id=${userId}`),
	};
}

export function acceptFriend(userId) {
	return {
		types: [ACCEPT_FRIEND, ACCEPT_FRIEND_SUCCESS, ACCEPT_FRIEND_FAIL],
		promise: (client) => client.put(`/friend/invitation?id=${userId}`),
	};
}

export function deleteFriend(userId) {
	return {
		types: [DELETE_FRIEND, DELETE_FRIEND_SUCCESS, DELETE_FRIEND_FAIL],
		promise: (client) =>
			client.delete(`/friend`, {
				params: {
					id: userId,
				},
			}),
	};
}

export function setUserStatus(status) {
	return {
		type: SET_USER_STATUS,
		status,
	};
}

export function setUserSuspension(to, code) {
	return {
		type: SET_USER_SUSPENSION,
		suspension: {
			duration: Math.floor(to - Date.now() / 1000),
			to,
			code,
		},
	};
}

export function clearUserSuspension() {
	return {
		type: SET_USER_SUSPENSION,
		suspension: {
			duration: 0,
			to: -1,
			code: -1,
		},
	};
}

export const setUserLimit = (limit) => ({
	type: SET_USER_LIMIT,
	payload: limit,
});
