import { combineReducers } from "redux";
import friends from "./userpanel/friends";
import notifications from "./userpanel/notifications";
import payment from "./userpanel/payment";
import photos from "./userpanel/photos";
import settings from "./userpanel/settings";
import videos from "./userpanel/videos";
import posts from "./userpanel/posts";

const userpanelReducer = combineReducers({
	friends,
	notifications,
	payment,
	photos,
	settings,
	videos,
	posts,
});

export default userpanelReducer;
