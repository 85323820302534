import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import config from "configuration";

export const i18nInitOptions = {
	detection: {
		order: ["cookie", "header"],
		caches: ["cookie"],
	},
	initImmediate: true,
	debug: false,
	fallbackLng: "pl",
	load: "languageOnly",
	ns: [
		"common",
		"contact",
		"countries",
		"filtersAndSelectors",
		"landing",
		"languages",
		"notifications",
		"pages",
		"regions",
		"report",
		"suspensions",
		"userpanel",
		"userProfile",
	],
	defaultNS: "common",
	supportedLngs: ["pl", "en", "de", "cs", "ru", "uk"],
	preload: false,
	react: {
		useSuspense: false,
	},
};

if (config.MODE.__DEVELOPMENT__) {
	i18n.use(LanguageDetector)
		.use(initReactI18next)
		.init(i18nInitOptions, () => {
			import("../locales").then(({ default: locales }) => {
				Object.keys(locales).forEach((lang) =>
					Object.keys(locales[lang]).forEach((namespace) =>
						i18n.addResourceBundle(
							lang,
							namespace,
							locales[lang][namespace]
						)
					)
				);
			});
		});
} else {
	if (process && !process.release) {
		i18n.use(Backend).use(initReactI18next).use(LanguageDetector);
	}
	if (!i18n.isInitialized) {
		i18n.init(i18nInitOptions);
	}
}

export default i18n;
